import JSBI from "jsbi"

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  JIBCHAIN_MAINNET = 8899,
}
export enum RoundingMode {
  RoundDown = 0,
  RoundHalfUp = 1,
  RoundHalfEven = 2,
  RoundUp = 3,
}
export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS: { [key: string]: string } = {
  [ChainId.JIBCHAIN_MAINNET]: "0x4BBdA880C5A0cDcEc6510f0450c6C8bC5773D499",
}

export const INIT_CODE_HASH =
  "0x9452f7377f905ca99b08ac3eb5f37cd011cfff83ba89ea72b93927b7cc4c73cd"

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = "uint8",
  uint256 = "uint256",
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt("0xff"),
  [SolidityType.uint256]: JSBI.BigInt(
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
  ),
}
