import { Currency, DEV, Token } from "../../jibswap"
import React, { useMemo } from "react"
import styled from "styled-components"
import token from "../../tokens.json"

import EthereumLogo from "../../assets/images/jbc-logo.png"
import useHttpLocations from "../../hooks/useHttpLocations"
import { WrappedTokenInfo } from "../../state/lists/hooks"
import Logo from "../Logo"

export const getTokenLogoURL = (address: string): string => {
  const tokenInfo = token.tokens.find((t) => t.address === address)
  return tokenInfo?.logoURI || ""
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = "24px",
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined,
  )

  const srcs: string[] = useMemo(() => {
    if (currency === DEV) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === DEV) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return (
    <StyledLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  )
}
