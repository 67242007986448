import { ChainId } from "../../jibswap"
import React from "react"
import { isMobile } from "react-device-detect"
import { Text } from "rebass"

import styled from "styled-components"

import Logo from "../../assets/images/mainlogo.png"
import { useActiveWeb3React } from "../../hooks"
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from "../../state/wallet/hooks"

import { YellowCard, LightCard } from "../Card"
import Settings from "../Settings"
import Menu from "../Menu"

import { RowBetween } from "../Row"
import Web3Status from "../Web3Status"
import { ExternalLink } from '../../theme'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const Bridge = styled(LightCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.JIBCHAIN_MAINNET]: "JIBCHAIN L1",
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ""
  ]
  //const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween
        style={{ alignItems: "flex-start" }}
        padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img style={{ height: 50 }} src={Logo} alt="logo" />
            </UniIcon>
          </Title>
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && (
                <Bridge>
                  <ExternalLink
                    href={`https://bridge.jbcha.in/`}
                    style={{ color: "inherit", textDecoration: 'none' }}>
                    Bridge
                  </ExternalLink>
                </Bridge>
              )}
            </TestnetWrapper>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
              )}
            </TestnetWrapper>
            <AccountElement
              active={!!account}
              style={{ pointerEvents: "auto" }}>
              {account && userEthBalance ? (
                <BalanceText
                  style={{ flexShrink: 0 }}
                  pl="0.75rem"
                  pr="0.5rem"
                  fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} JBC
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <VersionSwitch /> */}
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
